import { render, staticRenderFns } from "./PublicNoticeMore.vue?vue&type=template&id=cb9e2be2&scoped=true"
import script from "./PublicNoticeMore.vue?vue&type=script&lang=js"
export * from "./PublicNoticeMore.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb9e2be2",
  null
  
)

export default component.exports