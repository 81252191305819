<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="publicNotices">
      <template slot="adSearch">
        <vm-search :label="$t('maintWorkOrder.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
      </template>
      <el-table-column prop="noticeTitle" :label="$t('publicNotice.noticeTitle')" min-width="100">
        <template slot-scope="scope">
          <router-link :to="'/publicNoticeDetail/' + scope.row.id">
            {{ scope.row.noticeTitle }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column prop="publishDate" :label="$t('publicNotice.publishDate')"></el-table-column>
      <el-table-column prop="creator" :label="$t('publicNotice.name')"></el-table-column>
    </vm-table>
  </div>
</template>

<script>

const moduleName = "publicNotices";

export default {
  data() {
    return {
      loading: false,
      search: {
        filter: "",
        allData: 1,
      },
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(pageIndex) {
      this.$refs.vmTable.getList(pageIndex);
    },
  },
};
</script>

<style lang="scss" scoped></style>
